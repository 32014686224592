import Button from '@/newComponents/Button/Button';
import { ButtonColor } from '@/newComponents/Button/Button.utils';

import styled, { css } from 'styled-components';

import { Color, spacing } from '@/global/style/variables';
import { HallonColor } from '@/global/utils/styleTypes';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import { above } from '@/global/style/breakpoint';

export const Container = styled.div<{ $background?: HallonColor; $fullWidth: boolean; $bleed: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ $background }) => $background};
  ${({ $fullWidth }) => $fullWidth && `width: 100%`}

  ${({ $bleed }) =>
    $bleed &&
    css`
      margin: 0 -${spacing.x2};

      ${above.sm`
        margin: 0 -${spacing.x3}; 
      `}
    `}
`;

export const IconWrapper = styled.div<{ color?: HallonColor }>`
  color: ${(props) => props.color};
  margin-top: 1px;
  svg {
    flex: 0 0 auto;
    margin-right: ${spacing.x1};
    width: 20px;
    height: 20px;
  }
`;

export const NoticeFieldButton = styled(Button)<{
  color: ButtonColor;
  $preset: NoticeFieldPreset;
}>`
  ${({ $preset }) => {
    let borderColor: HallonColor = Color.Gray3;

    switch ($preset) {
      case NoticeFieldPreset.Success:
        borderColor = Color.Forest;
        break;
      case NoticeFieldPreset.Notice:
        borderColor = Color.Blue;
        break;
      case NoticeFieldPreset.Error:
        borderColor = Color.Hallon1;
        break;
      case NoticeFieldPreset.Warning:
        borderColor = Color.Brown;
        break;
      default:
        borderColor = Color.Ocean;
        break;
    }
    return `
      border: 1px solid ${borderColor};
      color: ${Color.MineShaft}
    `;
  }}
`;

export const Border = styled.div<{ $borderColor?: HallonColor }>`
  height: 1px;
  width: 100%;
  ${({ $borderColor }) => $borderColor && `background-color: ${$borderColor};`}
`;

export const Inner = styled.div<{ $padding?: string }>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: ${spacing.x2};
  text-align: left;
  padding: ${(props) => props.$padding || spacing.x2};
`;

export const NoticeText = styled.div<{
  $useMaxLength: boolean;
  $fullWidthText: boolean;
  $textColor?: HallonColor;
}>`
  color: ${({ $textColor }) => $textColor};

  p,
  span {
    ${({ $textColor }) =>
      $textColor &&
      css`
        color: ${$textColor};
      `}
  }

  a {
    color: inherit;
    font-weight: normal;
    text-decoration: underline;

    &:hover {
      background-color: inherit;
    }
  }

  ${({ $useMaxLength }) =>
    $useMaxLength &&
    `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `}

  ${({ $fullWidthText }) =>
    $fullWidthText &&
    `
      width: 100%;
    `}
`;
